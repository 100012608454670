const axios = require('axios')
const moment = require('moment')
const PANEL_URL = process.env.VUE_APP_API_PANEL;

export const setUserInfo = (state, info) => {
  state.userInfo = info
}
export const setUser = (state, info) => {
  state.user = info
}
export const setUserTutorial = (state, tutorial) => {
  state.user.tutorial[tutorial] = true
}
export const setAccount = (state, info) => {
  state.account = info
}
export const setLiveSettings = (state, payload) => {
  if (payload.away) {
    state.liveSettings.away = payload.away
    if (payload.away.options && payload.away.options.length > 0) {
      payload.away.options.forEach((each, eachIndex) => {
        state.liveSettings.away.options[eachIndex].value = each._id
      })
    }
  }
  if (payload.awayAuto) state.liveSettings.awayAuto = payload.awayAuto
  if (payload.attendantBell) state.liveSettings.attendantBell = payload.attendantBell
  if (payload.messageBell) state.liveSettings.messageBell = payload.messageBell
  if (payload.initiations) state.liveSettings.initiations = payload.initiations
  if (payload.messages) state.liveSettings.messages = payload.messages
  if (payload.queue) state.liveSettings.queue = payload.queue
}
export const setLiveAttendantSettings = (state, payload) => {
  if (payload.hasOwnProperty('attendantBell')) state.liveAttendantSettings.attendantBell = payload.attendantBell
  if (payload.hasOwnProperty('messageBell')) state.liveAttendantSettings.messageBell = payload.messageBell
  if (payload.hasOwnProperty('sortType')) state.liveAttendantSettings.sortType = payload.sortType
  if (payload.hasOwnProperty('exhibition')) state.liveAttendantSettings.exhibition = payload.exhibition
  if (payload.hasOwnProperty('exhibitionType')) state.liveAttendantSettings.exhibitionType = payload.exhibitionType
  if (payload.hasOwnProperty('exhibitionAlertTime')) state.liveAttendantSettings.exhibitionAlertTime = payload.exhibitionAlertTime
}
export const setZion = (state, payload) => {
  if (payload.hasOwnProperty('status')) state.zion.status = payload.status
}
export const setLiveDepartments = (state, payload) => {
  // clear
  state.liveDepartment = {}
  state.liveDepartments = []
  state.liveDepartmentsOptions = []
  // begin
  state.liveDepartments = payload
  payload.forEach(each => {
    state.liveDepartment[each._id] = each
    state.liveDepartmentsOptions.push({ label: each.label, value: each._id })
  })
}
export const setAccountCredits = (state, credits) => {
  state.credits = credits
}
export const setBotsDeleted = (state, bots) => {
  state.botsDeleted = bots
}
export const setBots = (state, bots) => {
  state.bots = bots
}
export const setBotsName = (state, botsName) => {
  state.botsName = botsName
}
export const setBotsId = (state, botsId) => {
  state.botsId = botsId
}
export const setBotsRaw = (state, botsRaw) => {
  state.botsRaw = botsRaw
}
export const setBotsById = (state, botsById) => {
  state.botsById = botsById
}
export const setBotsSelectable = (state, botsSelectable) => {
  state.botsSelectable = botsSelectable
}
export const setBot = (state, bot) => {
  state.bot = bot
}
export const setToken = (state, token) => {
  state.token = token
}
export const setLiveQuery = (state, query) => {
  state.liveQuery = query
}
export const setVisibility = (state, boolean) => {
  state.dark = boolean
}
export const setProduction = (state) => {
  state.api = state.environment.production
}
export const setHomologation = (state) => {
  state.api = state.environment.homologation
}
export const setDevelopment = (state) => {
  state.api = state.environment.development
}
export const setPermission = (state, permission) => {
  state.permission = permission
}
export const setTags = (state, tags) => {
  state.tags = tags
  let index = ''
  state.tagsOptions = []
  for (index in tags) {
    state.tagsOptions.push({
      label: tags[index].title,
      value: tags[index]._id
    })
  }
}
export const setUsers = (state, users) => {
  state.users = users
  let index = ''
  state.usersOptions = []
  for (index in users) {
    if (users[index].status) {
      state.usersOptions.push({
        label: users[index].n,
        value: users[index]._id.toString()
      })
    }
  }
}
export const setLiveTokens = (state, tokens) => {
  state.live.tokens = tokens
}
export const setLiveTokensOptions = (state, tokensOptions) => {
  state.live.tokensOptions = tokensOptions
}
export const setLiveDepartment = (state, department) => {
  state.live.department = department
}
export const setLiveDepartmentOptions = (state, departmentOptions) => {
  state.live.departmentOptions = departmentOptions
}
export const setLiveSound = (state, sound) => {
  state.live.sound = sound
}
export const setTitle = (state, title) => {
  state.title = title
}
export const setAudio = (state, audio) => {
  if (audio.hasOwnProperty('isRecording')) state.audio.isRecording = audio.isRecording
}
export const setBreadcrumbs = (state, payload) => {
  state.breadcrumbs = {
    title: '',
    help: null,
    paths: []
  }
  if (payload.title) state.breadcrumbs.title = payload.title
  if (payload.help) state.breadcrumbs.help = payload.help
  if (payload.paths) state.breadcrumbs.paths = payload.paths
}
export const setLiveSoundMessageBell = (state, sound) => {
  state.live.soundMessageBell = sound
}
export const setLiveSoundIsActiveMessageBell = (state, sound) => {
  state.live.soundIsActiveMessageBell = sound
}
export const initialPreferences = async (state, data) => {
  state.preferences = data
}
export const setPreferences = async (state, obj) => {
  if (obj.type) {
    switch (obj.type) {
      case 'conversation':
        state.preferences.conversation = obj.payload
        break
      case 'live':
        state.preferences.live = obj.payload
        break
    }
    await axios.post(`${PANEL_URL}/user/update/preferences`, {
      preferences: state.preferences
    })
  }
}
export const setBotsIdentifiers = async (state, data) => {
  state.botsIdentifiers[data.token] = data.identifiers
}
export const setLiveStatus = async (state, status) => {
  state.liveStatus = status
}
export const setDefault = async (state) => {
  state.title = ''
  state.bots = []
  state.botsName = {}
  state.botsId = {}
  state.botsRaw = {}
  state.botsIdentifiers = {}
  state.bot = {}
  state.tags = {}
  state.tagsOptions = []
  state.token = null
  state.userInfo = {}
  state.user = {}
  state.account = {}
  state.socket = null
  state.credits = 0
  state.liveQuery = {
    isMaster: true,
    masterId: null,
    masterName: null,
    masterEmail: null,
    masterRole: null,
    masterAvatar: null,
    tokens: [],
    category: null,
    department: null
  }
  state.live = {
    tokens: null,
    tokensOptions: null,
    department: null,
    departmentOptions: null,
    sound: null,
    soundMessageBell: null,
    soundIsActiveMessageBell: null
  }
  state.liveStatus = 1
  state.preferences = {
    conversation: {},
    live: {}
  }
}
export const disableAlarm = async (state) => {
  state.alarm.isActive = false
  state.alarm.tolerance = null
  state.alarm.display = ''
  state.alarm.counter = null
}
export const enableAlarm = async (state) => {
  state.alarm.isActive = true
}
export const setToleranceAlarm = async (state, payload) => {
  state.alarm.tolerance = payload
}
export const setDisplayAlarm = async (state, payload) => {
  state.alarm.display = payload
}
export const setCounterAlarm = async (state, payload) => {
  state.alarm.counter = payload
}
export const enableAlarmYellow = async (state) => {
  state.alarm.dialogYellow = true
}
export const disableAlarmYellow = async (state) => {
  state.alarm.dialogYellow = false
}
export const enableAlarmRed = async (state) => {
  state.alarm.dialogRed = true
}
export const disableAlarmRed = async (state) => {
  state.alarm.dialogRed = false
}
export const initAdvancedSearch = async (state) => {
  state.advancedSearch.match.cdt.$gte = moment().hours(0).minutes(0).seconds(0).milliseconds(0).subtract(7, 'day').format('YYYY-MM-DDTHH:mm:ss.sssZ')
  state.advancedSearch.match.cdt.$lte = moment().hours(23).minutes(59).seconds(59).milliseconds(999).format('YYYY-MM-DDTHH:mm:ss.sssZ')
}
export const advancedSearchMatch = async (state, payload) => {
  if (payload.period || payload.period === null) state.advancedSearch.match.period = payload.period
  if (payload.cdt && payload.cdt.$gte) state.advancedSearch.match.cdt.$gte = payload.cdt.$gte
  if (payload.cdt && payload.cdt.$lte) state.advancedSearch.match.cdt.$lte = payload.cdt.$lte
  if (payload.ih && payload.ih.$in) state.advancedSearch.match.ih.$in = payload.ih.$in
  if (payload.hb) state.advancedSearch.match.hb = payload.hb
  if (payload.tags) state.advancedSearch.match.tags = payload.tags
  if (payload.rating) state.advancedSearch.match.rating = payload.rating
  if (payload.dialogue) state.advancedSearch.match.dialogue = payload.dialogue
  if (payload.live) state.advancedSearch.match.live = payload.live
  if (payload.attendants) state.advancedSearch.match.attendants = payload.attendants
  if (payload.departments) state.advancedSearch.match.departments = payload.departments
  if (payload.cIdentifiers) state.advancedSearch.match.cIdentifiers = payload.cIdentifiers
  if (payload.cIdentifierFill) {
    state.advancedSearch.match.cIdentifierFill = payload.cIdentifierFill
  } else {
    state.advancedSearch.match.cIdentifierFill = false
  }
  if (payload.cResponse || typeof payload.cResponse === 'string') state.advancedSearch.match.cResponse = payload.cResponse
  if (payload.cResponseIdentifiers) state.advancedSearch.match.cResponseIdentifiers = payload.cResponseIdentifiers
  if (payload.cFinished) state.advancedSearch.match.cFinished = payload.cFinished
  if (payload.faqCategory) state.advancedSearch.match.faqCategory = payload.faqCategory
  if (payload.r) state.advancedSearch.match.r = payload.r
}
export const advancedSearchPeriod = async (state, payload) => {
  state.advancedSearch.match.period = payload
}
export const advancedSearchOptions = async (state, payload) => {
  if (payload.chatbots) state.advancedSearch.options.chatbots = [{ label: 'Interno', value: null }].concat(payload.chatbots)
  if (payload.identifiers) state.advancedSearch.options.identifiers = payload.identifiers
  if (payload.departments) state.advancedSearch.options.departments = payload.departments
  if (payload.attendants) state.advancedSearch.options.attendants = payload.attendants
}
export const advancedSearchGauge = async (state, payload) => {
  if (payload.total) state.advancedSearch.gauge.total = payload.total
  if (payload.count) state.advancedSearch.gauge.count = payload.count
  if (payload.average) state.advancedSearch.gauge.average = payload.average
}
export const advancedSearchSetDefault = async (state) => {
  state.advancedSearch.match.ih.$in = state.advancedSearch.options.chatbots.map(e => {
    if (e.value) return e.value
  })
  state.advancedSearch.match.hb = 'Todos'
  state.advancedSearch.match.dialogue = 'Todos'
  state.advancedSearch.match.tags = []
  state.advancedSearch.match.rating = []
  state.advancedSearch.match.live = null
  state.advancedSearch.match.attendants = []
  state.advancedSearch.match.departments = []
  state.advancedSearch.match.cIdentifiers = []
  state.advancedSearch.match.cIdentifierFill = true
  state.advancedSearch.match.cResponse = ''
  state.advancedSearch.match.cResponseIdentifiers = []
  state.advancedSearch.match.cFinished = 'Todas'
  state.advancedSearch.match.period = 7
}

export const enableWarningLostConnection = async (state) => {
  state.lostConnection.isActive = true
}
export const disableWarningLostConnection = async (state) => {
  state.lostConnection.isActive = false
}
